import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IAccessToken, IAccessTokenHistory} from '../model/cityIdcore/access-token.model';
import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';
import { OperationLog } from '../component/operation-log/operation-log.component';

export class CreateRequest {
  count: number;
  programId: number;
  poolId: number;
  printed: boolean;
  productId?: number;
}

type EntityResponseType = HttpResponse<IAccessToken>;
type EntityArrayResponseType = HttpResponse<IAccessToken[]>;

export type AccessTokenCountByProgramsAndOrganisationsType = Map<string, Map<string, number>>;

@Injectable({providedIn: 'root'})
export class AccessTokenService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/access-tokens';

  constructor(protected http: HttpClient) {
  }

  create(accessToken: IAccessToken): Observable<EntityResponseType> {
    return this.http.post<IAccessToken>(this.resourceUrl, accessToken, {observe: 'response'});
  }

  update(accessToken: any, comment?: string): Observable<EntityResponseType> {
    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('comment', comment);
    return this.http.put<any>(this.resourceUrl, accessToken, { params: requestParams, observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IAccessToken>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  findByToken(token: string, id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IAccessToken[]>(`${this.resourceUrl}-program/${id}/${token}`, {observe: 'response'});
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IAccessToken[]>(this.resourceUrl, {params: options, observe: 'response'});
  }

  findByParticipantId(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IAccessToken[]>(`${this.resourceUrl}-participant/${id}`, {observe: 'response'});
  }

  getHistory(id: number) {
    return this.http.get<IAccessTokenHistory[]>(`${this.resourceUrl}-history/${id}`, {observe: 'response'});
  }

  getOperationLogs(id: number) {
    return this.http.get<OperationLog[]>(`${this.resourceUrl}-operation-logs/${id}`, {observe: 'response'});
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  uploadAllNew(accessToken: IAccessToken[], programId: number) {
    return this.http.post<any[]>(`${this.resourceUrl}-upload/${programId}/add`, accessToken, { observe: 'response' });
  }

  uploadAllUpdate(accessToken: IAccessToken[], programId: number, resetPrintDate: boolean) {
    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('resetPrintDate', resetPrintDate);
    return this.http
      .post<any[]>(`${this.resourceUrl}-upload/${programId}/update`, accessToken, {params: requestParams, observe: 'response' });
  }

  findUnprintedAccessTokens(programIdsToPrint: number[], fromDate: string, toDate: string, cardTypes: string): Observable<AccessTokenCountByProgramsAndOrganisationsType> {
    let requestParams: HttpParams = new HttpParams();
    if (fromDate) {
      requestParams = requestParams.set('fromDate', fromDate);
    }
    if (toDate) {
      requestParams = requestParams.set('toDate', toDate);
    }
    requestParams = requestParams.set('cardTypes', cardTypes)
    return this.http.post<AccessTokenCountByProgramsAndOrganisationsType>(`${this.resourceUrl}/unprinted`, programIdsToPrint, { params: requestParams, observe: 'body' });
  }

  getPrintableCollumns(): Observable<string[]> {
    return this.http.get<string[]>(`${this.resourceUrl}/printable-columns`, { observe: 'body' });
  }

  printCardsAndMarkAccessTokensAsPrinted
    (programIdsToPrint: number[],
      fromDate: string,
      toDate: string,
      singleXls: boolean,
      printableColumns: string,
      cardTypes: string,
      markAsPrinted?: boolean): Observable<HttpResponse<Blob>> {
    let requestParams: HttpParams = new HttpParams();
    if (fromDate) {
      requestParams = requestParams.set('fromDate', fromDate);
    }
    if (toDate) {
      requestParams = requestParams.set('toDate', toDate);
    }
    requestParams = requestParams.set('singleXls', singleXls);
    requestParams = requestParams.set('markAsPrinted', markAsPrinted);
    requestParams = requestParams.set('printableColumns', printableColumns);
    requestParams = requestParams.set('cardTypes', cardTypes)
    return this.http.post<Blob>(`${this.resourceUrl}/print-cards`, programIdsToPrint, { params: requestParams, observe: 'response', responseType: 'blob' as 'json' });
  }

  replaceToken(id: number, comment?: string): Observable<IAccessToken> {
    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('comment', comment);
    return this.http.put<IAccessToken>(this.resourceUrl + "/" + id + "/replace", null, { params: requestParams, observe: 'body' });
  }

  createTokens(request: CreateRequest) : Observable<IAccessToken[]> {
    return this.http.post<IAccessToken[]>(this.resourceUrl + "/create", request);
  }

}
